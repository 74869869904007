import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

export default () => {
  
  return (
    <Layout>
      <SEO title="Home" />
      <section className="community-guidelines-banner background-overlay">
        <h1 className="banner-title">COMMUNITY<br />GUIDELINES</h1>
      </section>

      <section className="community-guidelines-content">
        <p>
          Last revised on 21st July 2020
    </p>
        <p>
          Welcome to the Yitnow community. Our goal is to allow users to meet up in real life (“irl”) and give them a way to find the best places to meet each other. Honest, kind people who express themselves freely will get the most benefit from this app and the community. We ask our users to not intentionally seek to cause offence or bad experiences for other users. Everyone is held to the same standard on Yitnow. We’re asking you to be considerate, think before you act, and abide by our community guidelines whilst using our service or irl as a direct consequence of using our service i.e. meeting up with other Yitnow users after meeting them initially on Yitnow.
    </p>
        <p>
          Below is a list of our community policies. If you violate any of these policies, you will be banned from Yitnow. We encourage you to <a href="/contact/">report</a> any behavior that violates our policies, and read up on our <a href="/safety/">Safety Tips</a>. Reports of objectionable content will be taken very seriously, the Yitnow team investigates all claims within 24 hours, removing offending content and ejecting users who proffer such content.
    </p>
        <p>
          <strong>Despite being a community for those of ages 18 and above (adults) Yitnow is not for:</strong>
        </p>
        <div className="divider"></div>
        <h2>NUDITY/SEXUAL CONTENT</h2>
        <p>
          It is not acceptable to post nudity, sexually explicit content, or to use sexually explicit language or chronicle all of your sexual desires via our services. Keep it clean.
    </p>
        <div className="divider"></div>
        <h2>HARASSMENT</h2>
        <p>
          Do not engage, or encourage others to engage, in any targeted abuse or harassment against any other user. This includes sending any unsolicited sexual content to your matches. Reports of stalking, threats, bullying, or intimidation, are taken very seriously.
    </p>
        <div className="divider"></div>
        <h2>VIOLENCE AND PHYSICAL HARM</h2>
        <p>
          We do not tolerate violent, graphic, or gory content on Yitnow, or any actions or content that advocate for or threaten violence of any sort, including threatening or promoting terrorism. Physical assault, coercion, and any acts of violence are strictly prohibited.
    </p>
        <p>
          Content that advocates for or glorifies suicide or self-harm/ self-harming behaviours is also not allowed. In these situations, we may (or may not) take a number of steps to assist the user, including reaching out with crisis resources.
    </p>
        <div className="divider"></div>
        <h2>ANTI-SOCIAL BEHAVIOUR</h2>
        <p>
          If we think you are making Yitnow a less friendly and welcoming place than it should be for other users by creating insulting, nasty or offensive content (as deemed by our representatives) we will ban you and terminate your account immediately without notice. If you wish us to make a judgement on such content please <a href="/contact/">report</a> it along with evidence. That being said this is a community for adults and all users have the ability to not use Yitnow (leaving the community and deleting the app) if it is causing them distress. Yitnow is a place for open minded people; if you have a glassy heart then a platform where you will encounter people with different values to your own may not be for you.
    </p>
        <div className="divider"></div>
        <h2>PRIVATE INFORMATION</h2>
        <p>
          Don’t publicly broadcast any private information, yours or anyone else’s. This includes social security numbers, passports, passwords, financial information or unlisted contact information, such as phone numbers, email addresses, home/work address.
    </p>
        <div className="divider"></div>
        <h2>SPAM PROMOTION OR SOLICITATION</h2>
        <p>
          With the exception of advertising via our bespoke advertising tools (Campaign Builder etc.):
    </p>
        <p>
          Don’t use Yitnow to drive people to external websites via a link or otherwise. Soliciting other users is prohibited on Yitnow. It’s fine to invite people to something that you’re doing, but if you are using Yitnow to advertise your event or business, non-profit, political campaign, contest, or to conduct research, we may delete your account without notice.
    </p>
        <div className="divider"></div>
        <h2>ILLEGAL ACTIVITY</h2>
        <p>
          Please <a href="/contact/">report</a> any and/or evidence of illegal activity you see on this service both to us and to the appropriate local authorities (usually the police), we will investigate and terminate the accounts of anyone we reasonably suspect to be associating our platform or seeking to associate our platform with illegal activity.
    </p>
        <div className="divider"></div>
        <h2>SCAMMING</h2>
        <p>
          Yitnow has a zero-tolerance policy on predatory behavior of any kind. Anyone attempting to get other users’ private information for fraudulent or illegal activity may be banned. Any user caught sharing their own financial account information (PayPal, Venmo, etc.) for the purpose of receiving money from other users may also be banned from Yitnow.
    </p>
        <div className="divider"></div>
        <h2>IMPERSONATION</h2>
        <p>
          Be yourself! Don’t pretend to be someone else.
    </p>
        <p>
          Do not impersonate, or otherwise misrepresent affiliation, connection or association with, any person or entity. This includes parody accounts.
    </p>
        <div className="divider"></div>
        <h2>MINORS</h2>
        <p>
          You must be 18 years of age or older to use Yitnow. As such, we do not allow images of unaccompanied minors. If you want to post photos of your children, please make sure that you are in the photo as well. If you see a profile that includes an unaccompanied minor, encourages harm to a minor, or depicts a minor in a sexual or suggestive way, please report it immediately.
    </p>
        <div className="divider"></div>
        <h2>COPYRIGHT AND TRADEMARK INFRINGEMENT</h2>
        <p>
          If it’s not yours, don’t post it. If your Yitnow profile includes any work that is copyrighted or trademarked by others, don’t display it, unless you are allowed to do so.
    </p>
        <div className="divider"></div>
        <h2>ONE PERSON, ONE ACCOUNT</h2>
        <p>
          Yitnow accounts cannot have multiple owners, so don’t create an account with your friend or significant other. Additionally, please don’t maintain multiple Yitnow accounts or they will all be terminated.
    </p>
        <div className="divider"></div>
        <h2>THIRD PARTY APPS</h2>
        <p>The use of any apps created by anyone other than Yitnow that claim to offer our service or unlock special Yitnow features is not allowed.</p>
        <div className="divider"></div>
        <h2>ACCOUNT DORMANCY</h2>
        <p>If you don’t log in to your Yitnow account in 2 years, we may delete your account for inactivity.</p>
      </section>
    </Layout>
  )
};

